.numberTitle {
  margin-left: 35px;
  margin-top: 20px;
  width: 50px;
  height: 50px;
  background-color: #141925;
  border-radius: 50%;
}

.numberTitleHat {
  margin-left: 35px;
  margin-top: 20px;
  width: 50px;
  height: 50px;
  background-color: #092ea9;
  border-radius: 50%;
}

.text-Title {
  font-size: 30px;
  color: #ffffff;
  margin-left: 16px;
}
.titleStyle {
  margin-left: 35px;
  margin-top: 25px;
}
.titleStyleHat {
  margin-left: 35px;
  margin-top: 25px;
  color: #092ea9;
}
.Items {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
}
.Item {
  margin: 20px;
  background-color: #fafafa;
  border: 1px #d3d3d3 solid;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.amount {
  font-size: 20px;
  width: 15%;
  color: #000000;
  text-align: center;
  border-right: 1px #d3d3d3 solid;
}
.amount1 {
  font-size: 20px;
  width: 15%;
  padding-top: 15px;
  text-align: center;
  border-bottom: 1px #d3d3d3 solid;
}
.numberOrder {
  font-size: 20px;
  width: 150px;
  font-weight: bold;
  text-align: center;
}
.price {
  font-size: 20px;
  width: 20%;
  font-weight: bold;
  text-align: center;
}

.prices1 {
  font-size: 20px;
  text-align: right;
  font-size: 20px;
  border-bottom: 1px #d3d3d3 solid;
  border-right: 1px #d3d3d3 solid;
  padding-right: 20px;
  width: 20%;
  padding-top: 15px;
}
.image {
  font-size: 20px;
  width: 15%;
  font-weight: bold;
  text-align: center;
  border-right: 1px #d3d3d3 solid;
}
.image1 {
  width: 15%;
  margin-top: 15px;
  padding-bottom: 15px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  border-bottom: 1px #d3d3d3 solid;
}

.serial {
  text-align: center;
  font-size: 20px;
  width: 15%;
  font-weight: bold;
  border-right: 1px #d3d3d3 solid;
}
.serial1 {
  text-align: center;
  font-size: 20px;
  padding-top: 15px;
  width: 15%;
  display: flex;
  justify-content: center;
  border-bottom: 1px #d3d3d3 solid;
  border-left: 1px #d3d3d3 solid;
}

.nameProduct {
  text-align: center;
  font-size: 20px;
  width: 40%;
  border-right: 1px #d3d3d3 solid;
  color: #000000;
}
.nameProduct1 {
  margin-top: 15px;
  font-size: 20px;
  width: 40%;
  display: flex;
  padding-left: 20px;
  font-weight: bold;
  justify-content: left;
  border-bottom: 1px #d3d3d3 solid;
}

.priceProduct {
  font-size: 20px;
  color: #9c9ca2;
  margin-left: 20px;
}
.orderingText {
  width: 130px;
  font-size: 17px;
  color: #9c9ca2;
}
.oderinginfor {
  display: flex;
  margin-left: 7%;
}
.addressShip {
  margin-left: 17%;
}
.email {
  margin-left: 17%;
}
.imgProduct {
  width: 75px;
  height: 55px;
}
.btnCancel {
  margin-left: 10px;
}
.loginTitle {
  justify-content: center;
  margin-left: 100px;
  font-weight: bold;
  margin-bottom: -10px;
}

.name {
  max-width: '100%';
  font-size: 18px;
  margin-right: 10px;
  color: #000000;
  /* overflow-y: auto; */
  word-wrap: break-word;
  font-family: 'arial';
}
.timeOrder {
  margin-left: 15px;
  max-width: 19ch;
  font-size: 25px;
  color: #000000;
}
.button3 {
  margin-left: 20px;
}
.button2:hover {
  background-color: #008cba;
  color: white;
}
.Scale {
  display: flex;
  flex-wrap: wrap;
}
.CenterButton {
  text-align: center;
}
.ChangeSizeNotifica {
  text-align: center !important;
  width: 100% !important;
  /* margin-left: 2% !important; */
  margin: 0%;
}

@media (max-width: 1024px) {
  .top-element {
    /* flex-direction: column; */
    /* align-items: flex-start; */
  }

  .App {
    padding: 15px !important;
  }

  .col-left {
    margin-right: 0 !important;
    margin-bottom: 20px;
  }
}
